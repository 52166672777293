<template>
    <div class="install">
        <s-header :name="$t('LAN.install')"></s-header>
        <div  class="pay_title">
            是否开启密码支付
        </div>
        <div>
            <van-radio-group  v-model="checked" direction="horizontal" class="pay_switch">
                <van-radio name="0" @click="clickRadio" >开启</van-radio>
                <van-radio name="1" @click="clickRadio" >关闭</van-radio>
            </van-radio-group>
        </div>
   

        <div style="margin: 16px;">
            <van-button round block type="primary" style="background: rgb(110, 135, 234);" native-type="submit" @click="onSubmit"> 提交  </van-button>
        </div>
    </div>

</template>

<script>
import { ref,onMounted } from "vue";
import sHeader from '@/components/Header';
import { requestPayfale} from '@/service/pay' 
import { Toast } from "vant";
import {useRouter,useRoute } from "vue-router";
export default {
    setup() {
        const checked = ref('1');
        const router = useRouter();
        const route = useRoute(); //获取url上的参数
        const clickRadio = ()=>{
            console.log(checked.value)
        }
        const goTo = (name, params) => {
            router.push({ name: name, query: params });
        }
        const onSubmit  = async () =>{
            let result =   await requestPayfale({ 
                payfale:checked.value
            });

            if(result.code == 0){
                Toast("已设置成功");
                goTo("points")
            }
        }

        onMounted(async () => {
            if(route.query.payFale){
                checked.value = route.query.payFale;
            }
        });

        return {
            checked,
            clickRadio,
            onSubmit,
            goTo
        }
    }, 
    components: {
        sHeader,
    }
}
</script>
<style>
.install {
    height: 100vh;
    .content {
        margin-top: 10px;
    }
}
.pay_title{
    text-align:left;
    font-size: 0.38647rem;
}
.pay_switch{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 15px;
}
</style>